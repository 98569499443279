import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import Markdown from "../components/Markdown"

const InfoPage = (props) => {
  const {
    data: {
      mdx: { body },
    },
  } = props

  return (
    <Layout>
      <SEO title="Event Info" />

      <div className="min-h-screen-nav bg-gray-50 px-4">
        <div className="prose lg:prose-xl mx-auto py-8 lg:py-16">
          <Markdown content={body} />
        </div>
      </div>
    </Layout>
  )
}

export default InfoPage

export const pageQuery = graphql`
  query {
    mdx(fileAbsolutePath: {regex: "/event-info.mdx/"}) {
      body
    }
  }
`